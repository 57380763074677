import "./style.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as dat from "lil-gui";
import vertexShader from "./shaders/vertex.glsl";
import fragmentShader from "./shaders/fragment.glsl";
import Stats from "stats.js";

/**
 * Base
 */
// Debug tools
const stats = new Stats();
stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
if (process.env.NODE_ENV === "development") {
  document.body.appendChild(stats.dom);
}

const gui = new dat.GUI({
  // stats.js usually has a width of 80px, so we leave 100px before dat.GUI
  width: Math.min(500, window.innerWidth - 100),
});
gui.domElement.style.right = 0;
gui.show(process.env.NODE_ENV === "development");

const toggleFullscreen = () => {
  const fullscreenElement =
    document.fullscreenElement || document.webkitFullscreenElement;
  if (!fullscreenElement) {
    if (canvas.requestFullscreen) {
      canvas.requestFullscreen();
    } else if (canvas.webkitRequestFullscreen) {
      canvas.webkitRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
};

const toggleDebugHelpers = () => {
  gui.show(gui._hidden);
  stats.dom.hidden = !stats.dom.hidden;
};

const onTripleClick = () => {
  toggleDebugHelpers();
};

// Event listeners
window.addEventListener("keypress", (e) => {
  if (e.key === "h") {
  }
});

const lastTouches = {
  secondLast: 0,
  last: 0,
};
const tripleClickListener = () => {
  let now = Date.now();
  if (now - lastTouches.secondLast < 400) {
    // triple tap registered
    onTripleClick();
    lastTouches.last = 0;
    lastTouches.secondLast = 0;
  } else {
    lastTouches.secondLast = lastTouches.last;
    lastTouches.last = now;
  }
};
window.addEventListener("click", tripleClickListener);
window.addEventListener("touchstart", tripleClickListener);

const parameters = {
  fullscreen: toggleFullscreen,
  zSpread: 20,
  particleSize: 10 * Math.min(window.devicePixelRatio, 2),
};

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Particles
 */
const readPixelData = () => {
  const canvas = document.createElement("canvas");
  canvas.width = imageTexture.image.width;
  canvas.height = imageTexture.image.height;

  // get the 2D context of the canvas
  var ctx = canvas.getContext("2d");

  // draw the particleTexture onto the canvas
  ctx.drawImage(imageTexture.image, 0, 0);

  // read the pixel data from the canvas
  var pixelData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

  // pixelData is a Uint8ClampedArray containing the pixel data in RGBA format
  return pixelData;
};

let positions = [];
let colors = [];
let alphas = [];

const updateColorAndPosition = () => {
  positions = [];
  colors = [];
  alphas = [];

  const imageAspectRatio = imageTexture.image.width / imageTexture.image.height;
  const width = particleSystemWidth;
  const height = Math.round(particleSystemWidth / imageAspectRatio);
  const samplingFactor = imageTexture.image.width / width;

  const xOffset = -width / 2;
  const yOffset = -height / 2;

  const pixelData = readPixelData();

  for (let i = 0; i < width; i++) {
    for (let j = 0; j < height; j++) {
      // down-/upsampled i, j, width values
      const i_s = Math.round(i * samplingFactor);
      const j_s = Math.round(j * samplingFactor);
      const width_s = Math.round(width * samplingFactor); // same as `imageTexture.image.width`
      const sampleIndex = (i_s + j_s * width_s) * 4;

      const r = pixelData[sampleIndex + 0] / 255;
      const g = pixelData[sampleIndex + 1] / 255;
      const b = pixelData[sampleIndex + 2] / 255;
      const alpha = pixelData[sampleIndex + 3] / 255;
      if (alpha > 0) {
        positions.push(
          i + xOffset,
          j + yOffset,
          Math.random() * parameters.zSpread
        );
        colors.push(r, g, b);
        alphas.push(alpha);
      }
    }
  }

  particlesGeometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(positions, 3)
  );
  particlesGeometry.setAttribute(
    "color",
    new THREE.Float32BufferAttribute(colors, 3)
  );
  particlesGeometry.setAttribute(
    "alpha",
    new THREE.Float32BufferAttribute(alphas, 1)
  );
};

gui.add(parameters, "fullscreen");

gui.add(parameters, "zSpread").min(0).max(100).onChange(updateColorAndPosition);

let particlesMaterial;
gui
  .add(parameters, "particleSize")
  .min(1)
  .max(50)
  .onChange((newValue) => {
    // for use with PointsMaterial
    particlesMaterial.size = newValue;
    // for use with RawShaderMaterial
    particlesMaterial.uniforms.uParticleSize.value = newValue;
  });

// Geometry
const particlesGeometry = new THREE.BufferGeometry();

/**
 * Textures
 */
let imageTexture;

const textureLoader = new THREE.TextureLoader();
imageTexture = textureLoader.load("/textures/procivis.png", () => {
  particlesMaterial = new THREE.ShaderMaterial({
    vertexColors: true,
    depthWrite: false,
    transparent: true,
    blending: THREE.AdditiveBlending,
    vertexShader,
    fragmentShader,
    uniforms: {
      uTime: { value: 0 },
      uParticleSize: { value: parameters.particleSize },
      uTime: { value: 0 },
      // zoom of OrthographicCamera
      uCameraZoom: { value: camera.zoom },
    },
  });

  // Points
  const particles = new THREE.Points(particlesGeometry, particlesMaterial);
  scene.add(particles);

  updateColorAndPosition();
});

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

// width relative to window width
const PARTICLE_SYSTEM_RELATIVE_WIDTH = 0.5;
// absolute max width
const PARTICLE_SYSTEM_MAX_WIDTH = 800;

let particleSystemWidth = Math.min(
  PARTICLE_SYSTEM_MAX_WIDTH,
  sizes.width * PARTICLE_SYSTEM_RELATIVE_WIDTH
);

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update particle system width
  particleSystemWidth = Math.min(
    PARTICLE_SYSTEM_MAX_WIDTH,
    sizes.width * PARTICLE_SYSTEM_RELATIVE_WIDTH
  );
  updateColorAndPosition();

  // Update camera
  camera.left = -sizes.width / 2;
  camera.right = sizes.width / 2;
  camera.top = sizes.height / 2;
  camera.bottom = -sizes.height / 2;

  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.OrthographicCamera(
  -sizes.width / 2,
  sizes.width / 2,
  sizes.height / 2,
  -sizes.height / 2
);
camera.position.z = 500;
camera.position.y = 0;
camera.position.x = 0;
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
// renderer.setClearColor("white");
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
  stats.begin();
  const elapsedTime = clock.getElapsedTime();

  // Update uniforms
  if (particlesMaterial) {
    particlesMaterial.uniforms.uTime.value = elapsedTime;
    particlesMaterial.uniforms.uCameraZoom.value = camera.zoom;
  }

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);

  stats.end();
};

tick();
